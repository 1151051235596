import React,{useState,useEffect} from 'react'
import {Link,navigate } from 'gatsby'
import Layout from '../layouts'
import Helmet from 'react-helmet'
import _ from 'lodash'
import Content,{HTMLContent} from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Lightbox from '../components/assets/plugins/react-image-lightbox';

export const DesignDetailTemplate = ({location, tags,category, images, colorimage, caserefurl, casetitle, casedesc, casecontent,prev_next,contentComponent}) => {
  const PageContent = contentComponent || Content
  const [isOpen,setIsOpen] = useState(false)
  const [photoIndex,setPhotoIndex] = useState(0)
  const tagss = tags ? tags.trim().split(/\s*,\s*/) : null
 
 useEffect(()=>{
  console.log(images,'data.markdownRemark.frontmatter')
 },[images])
  return (
    <React.Fragment>
      <Helmet defer={false}>
      <title>{`${casetitle} | JDSolutions`}</title>
      <meta property="og:title" content={`${casetitle} | JDSolutions`} />
        <meta property="og:type" content="design" />
        <meta property="og:image" content={colorimage.publicURL} />     
      </Helmet>
    <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell case-details">
    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell wrapper">
    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_jinja ">
        <div className="next-prev-case" style={{width:'auto'}}>
        <Link to="/designs" className="prev-list en-text cu44" style={{position:'relative'}}><span>Designs</span></Link >
        <Link to={`/designs/categories/${_.kebabCase(category)}/`} className="desktop prev-list en-text" style={{position:'relative',marginLeft:'20px'}}><span>{category}</span></Link >
        <span className=" desktop prev-list en-text" style={{position:'relative',marginLeft:'20px',fontWeight:'300',fontSize:'14px'}}><span>{casetitle}</span></span >
        
        </div>
        <div className="next-prev-case">
          <Link 
          to={prev_next[0].previous !== null ? prev_next[0].previous.fields.slug : null} 
          className={`next-list en-text ${prev_next[0].previous === null ? `disable`:``}`}>
            <span>Previous design</span></Link>
          <Link to={prev_next[0].next !== null ? prev_next[0].next.fields.slug : null} 
          className={`prev-list en-text ${prev_next[0].next === null ? `disable`:``}`} >
            <span>Next design</span></Link>
        </div>
        </div>        
        </div>
    </div>
    <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
      <div className="row-fluid ">
      <div className="span4 widget-span widget-type-cell cases-left-col">

<div className="row-fluid-wrapper row-depth-1 row-number-6 cust286">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell thumbnail company1" style={{position:'relative'}}  >
<div className="fade_img" onClick={()=>setIsOpen(true)}></div>
<div className="row-fluid-wrapper row-depth-2 row-number-1 ">
<div className="row-fluid ">
{photoIndex !== 0 && <i className="fa fa-chevron-circle-left" aria-hidden="true" onClick={()=>setPhotoIndex(photoIndex-1)}></i>}
{photoIndex !== images.length-1 && <i className="fa fa-chevron-circle-right" aria-hidden="true" onClick={()=>setPhotoIndex(photoIndex + 1)}></i>}

<div className="span12 widget-span widget-type-linked_image" >

<div className="cell-wrapper layout-widget-wrapper">
{images.map((el,i)=>{
  return(
<span style={{display: i === photoIndex ? 'block' : `none`}} className="cu767">
<PreviewCompatibleImage  imageInfo={{childImageSharp:el.childImageSharp}}/>
</span>
  )
})}

</div>

</div>
{images && images.length > 1 
&& 

<div className="cust-tray">
  {images.map((el,i)=>{

    return(<div className={`${photoIndex === i ? 'imgg active' : 'imgg inactive'}`} onClick={()=>setPhotoIndex(i)}>
    <img src={el.publicURL} alt="sl"/>
    </div>)
    })}
</div>}
</div>
</div>


</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-1 " style={{paddingTop:'40px'}}>
<div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text content">
<div className="cell-wrapper layout-widget-wrapper">
<span id="hs_cos_wrapper_module_1509296031895220" 
className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
<div className="en-text">
<h4><a href={caserefurl} 
target="_blank">{casetitle} </a>
</h4>
<p>{casedesc}</p>
</div>

</span>
</div>
</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-1 desktop" >
<div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text content cu25" style={{paddingTop:0,display:'flex'}}>
<div className="cell-wrapper layout-widget-wrapper" style={{display:'flex', justifyContent:'center',alignItems:'center',flexFlow:'column'}}>
<div className="mobile-class show-cat" style={{position:"relative",width:'100%'}}>
  <i className="fa fa-folder"></i>
  <Link to={`/designs/categories/${_.kebabCase(category)}/`}  >{category}</Link >
  </div>
<div className="show-tags" style={{width:'100%'}}>
 
<i className="fa fa-tag"></i>
{tagss && tagss.map((el,i)=>{
  return(<Link to={`/designs/tags/${_.kebabCase(el)}/`} key={`tag${i}`}>{el}</Link>)
}) }
</div>
</div>
</div>
</div>
</div>


</div>


<div className="span8 widget-span widget-type-cell cases-right-col">
    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
        <div className="row-fluid ">
            <div className="span12 widget-span widget-type-rich_text detail">
                <div className="cell-wrapper layout-widget-wrapper">
                    <span className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
                    <div className="en-text">
                    <PageContent className="content" content={casecontent} />
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-1 mobile" style={{marginBottom:'40px'}}>
<div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text content cu25" style={{paddingTop:0,display:'flex'}}>
<div className="cell-wrapper layout-widget-wrapper" style={{display:'flex', justifyContent:'center',alignItems:'center',flexFlow:'column',marginLeft:'-3px'}}>
<div className="mobile-class show-cat" style={{position:"relative",width:'100%'}}>
  <i className="fa fa-folder"></i>
  <Link to={`/designs/categories/${_.kebabCase(category)}/`}  >{category}</Link >
  </div>
<div className="show-tags" style={{width:'100%'}}>
 
<i className="fa fa-tag"></i>
{tagss && tagss.map((el,i)=>{
  return(<Link to={`/designs/tags/${_.kebabCase(el)}/`} key={`tag${i}`}>{el}</Link>)
}) }
</div>
</div>
</div>
</div>
</div>




    </div>

    </div>
      </div>
      </div>
      </div>
      </div>
      </div>

</div>
{isOpen &&
          <Lightbox
            enableZoom={true}
            animationDuration={1000}
            animationDisabled={true}
            animationOnKeyInput={false}
            next={undefined}
            mainSrc={images[photoIndex].publicURL}
            nextSrc={photoIndex === images.length-1 ? '' : images[(photoIndex + 1) % images.length].publicURL}
            prevSrc={photoIndex === 0 ? '' : images[(photoIndex + images.length - 1) % images.length].publicURL}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />}
      </React.Fragment>
  )
}


const DesignDetail = ({data,location}) => {
  const [page, setPage] = useState('case-detail formal design')
  const img = data.markdownRemark.frontmatter.featuredimage
  const img1 = data.markdownRemark.frontmatter.image_1
  const img2 = data.markdownRemark.frontmatter.image_2
  const img3 = data.markdownRemark.frontmatter.image_3
  const img4 = data.markdownRemark.frontmatter.image_4
  const img5 = data.markdownRemark.frontmatter.image_5
  const img6 = data.markdownRemark.frontmatter.image_6
  const img7 = data.markdownRemark.frontmatter.image_7
  const img8 = data.markdownRemark.frontmatter.image_8

  return (
    <Layout page={page}>
      <DesignDetailTemplate
      location={location}
      tags={data.markdownRemark.frontmatter.design_tags}
       images={[img && img, 
        img1 && img1,
        img2 && img2,
        img3 && img3,
        img4 && img4,
        img5 && data.markdownRemark.frontmatter.title === 'Scripta' ? null : img5,
        img6 && data.markdownRemark.frontmatter.title === 'Scripta' ? null : img6,
        img7 && data.markdownRemark.frontmatter.title === 'Scripta' ? null : img7,
        img8 && data.markdownRemark.frontmatter.title === 'Scripta' ? null : img8].filter(el=>{return el})}
       colorimage={data.markdownRemark.frontmatter.featuredimage}
       caserefurl={data.markdownRemark.frontmatter.caserefurl}
       casetitle={data.markdownRemark.frontmatter.title}
       category={data.markdownRemark.frontmatter.categories}
       casedesc={data.markdownRemark.frontmatter.description}
       casecontent={data.markdownRemark.html}
       prev_next={data.allMarkdownRemark.edges.filter(el=>(el.node.id === data.markdownRemark.id))}
       contentComponent={HTMLContent}/>
    </Layout>
  )
}



export default DesignDetail

export const pageQuery = graphql`
  query DesignByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        categories
        title
        design_tags
        designrefurl
        description
        featuredimage {
          publicURL
          childImageSharp{
                  fluid{
                    srcSet
                  }
                }
      }
      image_1 {
        publicURL
        childImageSharp{
                fluid{
                  srcSet
                }
              }
    }
      image_2 {
        publicURL
        childImageSharp{
                fluid{
                  srcSet
                }
              }
    }
      image_3 {
        publicURL
        childImageSharp{
                fluid{
                  srcSet
                }
              }
    }
      image_4 {
        publicURL
        childImageSharp{
                fluid{
                  srcSet
                }
              }
    }
      image_5 {
        publicURL
        childImageSharp{
                fluid{
                  srcSet
                }
              }
    }
      image_6 {
        publicURL
        childImageSharp{
                fluid{
                  srcSet
                }
              }
    }
      image_7 {
        publicURL
        childImageSharp{
                fluid{
                  srcSet
                }
              }
    }
        image_8 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                    }
                  }
        }
       
      }
    }
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "design-detail"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
        }
        previous {
          fields {
            slug
          }
        }
      next {
          fields {
            slug
          }
        }
      }
    }
  }
  
`
